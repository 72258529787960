import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import globalUseStyle from '../theme/customComponents';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';


const SaveWarning = ({ changePage, warningMessage, showDialog, setShowDialog }) => {
    // const dispatch = useDispatch();
    const globalClasses = globalUseStyle();

  return (
    <React.Fragment>
        <Dialog open={showDialog} maxWidth={false}>
            <Paper elevation={0} style={{ width: "546px", height: "244px", display: "flex", marginTop: "28px", flexDirection: "column" }}>
            <Typography style={{ fontSize: "28px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", alignItems: "center", paddingBottom: "10px", marginLeft: "24px" }} gutterBottom>
            You will lose all changes
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingTop: "25px", marginLeft: "24px" }} gutterBottom>
            {
              warningMessage === null
            ? "Are you sure you would like to go back? You will lose all your progress."
            : warningMessage
            }
            </Typography>
            <Divider variant="middle" style={{ marginTop: "28px", marginBottom: "28px" }}/>
            <DialogActions>
                <Button variant="outlined" className={globalClasses.cancelButton} onClick={() => setShowDialog(false)} >Cancel</Button>
                <Button className={globalClasses.dialogButton} style={{ width: "94px" }} onClick={() => { setShowDialog(false); changePage() }}>Go back</Button>
            </DialogActions>
            </Paper>
        </Dialog>
    </React.Fragment>
  );
}

export default SaveWarning;
