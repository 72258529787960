const globalColors = {
  brandColor1: "#2B78C2",
  brandColor2: "#FE7A10",
  textColor1: "#1C1C28",
  textColor2: "#555770",
  textColor3: "#8F90A6",
  textColor4: "#EBEBF0",
  backgroundColor1: "#FFFFFF",
  backgroundColor2: "#F7F9FB",
  borderColor: "#F1F1F3",
  semanticColor1: "#48D292",
  semanticColor2: "#0095FF",
  semanticColor3: "#FFCC00",
  semanticColor4: "#FF5757",
  extra1: "#E5EDFF"

};

export default globalColors;