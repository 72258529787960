import React from 'react';
import { connect } from 'react-redux';
import Dynamsoft from 'dwt';

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ScannerIcon from '@material-ui/icons/Scanner';

const DWTStyle = (theme) => ({
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#2B78C2"),
        backgroundColor: "#2B78C2",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        "&:hover": {
          backgroundColor: "#2B78C2",
          "@media (hover: none)": {
            backgroundColor: "#2B78C2"
          }
        },
        "&.Mui-disabled": {
            backgroundColor: "#2B78C2",
            opacity: 0.3,
            color: theme.palette.getContrastText("#2B78C2")
          }
      }
  })

class DWT extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            scanners: [],
            currentScanner: "",
            currentScannerIndex: -1,
            open: false
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveResponseString = this.saveResponseString.bind(this);
    }

    DWObject = null;
    containerId = 'dwtcontrolContainer';
    width = "100%";
    height = "600";

    handleClickOpen () {
        this.setState({ open: true });
    }

    handleClose (scanner) {
        this.setState({ currentScanner: scanner.value, currentScannerIndex: scanner.index });
        if (scanner.index >= 0) {
            this.props.setQcLoading(true);
            this.setState({ open: false }, () => this.acquireImage());
        } else {
            this.setState({ open: false });
        }
    }

    componentDidMount () {
        Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
            this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
            if (this.DWObject) {
                this.DWObject.RegisterEvent('OnBufferChanged', (bufferChangeInfo) => {
                    this.sendScannedImageForQC(bufferChangeInfo);
                });
                const vCount = this.DWObject.SourceCount;
                const sourceNames = [];
                for (let i = 0; i < vCount; i++) { sourceNames.push(this.DWObject.GetSourceNameItems(i)); }
                this.setState({ scanners: sourceNames });
                this.props.setScannerCount(sourceNames.length)
            }
        });
        this.loadDWT();
    }

    loadDWT () {
        //Dynamsoft.DWT.ResourcesPath = "../Resources";
        //Dynamsoft.DWT.ProductKey = process.env.REACT_APP_DYNAMOSOFT_LICENSE;
        // Dynamsoft.DWT.ProductKey = null;
        //Dynamsoft.DWT.Containers = [{ ContainerId: this.containerId }];
        //this.modulizeInstallJS();
        //Dynamsoft.DWT.Load();
    }

    acquireImage () {
        var cIndex = this.state.currentScannerIndex
        if (cIndex < 0) { return; }

        this.DWObject.SelectSourceByIndex(cIndex);
        this.DWObject.CloseSource();
        this.DWObject.OpenSource();

        var deviceConfiguration = {
            IfShowUI: false,
            PixelType: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
            Resolution: 200,
            IfFeederEnabled: false,
            IfDuplexEnabled: false,
            IfAutoDiscardBlankpages: false,
            IfDisableSourceAfterAcquire: true
        };

        this.DWObject.AcquireImage(deviceConfiguration,
            function () {},
            function (obj, errorCode, errorString) {});
    }

    sendScannedImageForQC (bufferChangeInfo) {
        if (bufferChangeInfo.action === 'add') {
            const imageIndex = this.DWObject.ImageIDToIndex(bufferChangeInfo.currentId)
            const today = new Date()
            const strTimestamp = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate() + '_' +
            today.getHours() + '_' + today.getMinutes() + '_' + today.getSeconds();
            const filename = strTimestamp + '_scanned_image.png';
            this.DWObject.SetHTTPFormField("batch_id", this.props.batchId);
            this.DWObject.HttpFieldNameOfUploadedImage = "image";
            this.DWObject.HTTPUploadThroughPostEx(
                process.env.REACT_APP_DYNAMOSOFT_API_ENDPOINT,
                imageIndex,
                "sheet/",
                filename,
                Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG,
                function () {}, // For empty response
                this.saveResponseString
            )
        }
    }

    saveResponseString (obj, errorCode, response) {
        this.props.setQcLoading(false);
        const res = JSON.parse(response);
        this.props.setScanResponse(res);
    }

    loadImagesOrPDFs () {
        this.DWObject.IfShowFileDialog = true;
        this.DWObject.Addon.PDF.SetResolution(200);
        this.DWObject.Addon.PDF.SetConvertMode(1/* Dynamsoft.DWT.EnumDWT_ConvertMode.CM_RENDERALL */);
        this.DWObject.LoadImageEx("", 5 /* Dynamsoft.DWT.EnumDWT_ImageType.IT_ALL */,
            () => { },
            (errorCode, errorString) => alert(errorString));
    }

    render () {
        return (
            <>
                <Dialog open={this.state.open} onClose={() => this.handleClose({ value: "", index: -1 })}>
                    <DialogTitle>Select Scanner</DialogTitle>
                    <List sx={{ pt: 0 }}>
                        {this.state.scanners.map((_scanner, _index) => (
                        <ListItem button onClick={() => this.handleClose({ value: _scanner, index: _index })} key={_scanner}>
                            <ListItemAvatar>
                            <Avatar className={this.props.classes.button}>
                                <ScannerIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={_scanner} />
                        </ListItem>
                        ))}
                    </List>
                </Dialog>
                <Button className={this.props.classes.button}
                    onClick={() => this.handleClickOpen()}
                    disabled={(this.state.scanners.length <= 0 || this.props.qcLoading === true)}
                    variant="contained" component="label" startIcon={<ScannerIcon></ScannerIcon>}>
                    Scan
                </Button>
            </>
        );
    }

    modulizeInstallJS () {
        const _DWTReconnect = Dynamsoft.DWT_Reconnect;
        Dynamsoft.DWT_Reconnect = (...args) => _DWTReconnect.call({ Dynamsoft: Dynamsoft }, ...args);
        const __showInstallDialog = Dynamsoft._show_install_dialog;
        Dynamsoft._show_install_dialog = (...args) => __showInstallDialog.call({ Dynamsoft: Dynamsoft }, ...args);
        // Dynamsoft._show_install_dialog = function () {};
        const _OnWebTwainOldPluginNotAllowedCallback = Dynamsoft.OnWebTwainOldPluginNotAllowedCallback;
        Dynamsoft.OnWebTwainOldPluginNotAllowedCallback = (...args) => _OnWebTwainOldPluginNotAllowedCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        const _OnWebTwainNeedUpgradeCallback = Dynamsoft.OnWebTwainNeedUpgradeCallback;
        Dynamsoft.OnWebTwainNeedUpgradeCallback = (...args) => _OnWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        // let _OnWebTwainPreExecuteCallback = Dynamsoft.OnWebTwainPreExecuteCallback;
        // Dynamsoft.OnWebTwainPreExecuteCallback = (...args) => _OnWebTwainPreExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        // let _OnWebTwainPostExecuteCallback = Dynamsoft.OnWebTwainPostExecuteCallback;
        // Dynamsoft.OnWebTwainPostExecuteCallback = (...args) => _OnWebTwainPostExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        Dynamsoft.DWT.OnWebTwainPreExecute = function () {};
        Dynamsoft.DWT.OnWebTwainPostExecute = function () {};
        const _OnRemoteWebTwainNotFoundCallback = Dynamsoft.OnRemoteWebTwainNotFoundCallback;
        Dynamsoft.OnRemoteWebTwainNotFoundCallback = (...args) => _OnRemoteWebTwainNotFoundCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        const _OnRemoteWebTwainNeedUpgradeCallback = Dynamsoft.OnRemoteWebTwainNeedUpgradeCallback;
        Dynamsoft.OnRemoteWebTwainNeedUpgradeCallback = (...args) => _OnRemoteWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        const _OnWebTWAINDllDownloadFailure = Dynamsoft.OnWebTWAINDllDownloadFailure;
        Dynamsoft.OnWebTWAINDllDownloadFailure = (...args) => _OnWebTWAINDllDownloadFailure.call({ Dynamsoft: Dynamsoft }, ...args);
    }
}

export default connect()(withStyles(DWTStyle, { withTheme: true })(DWT))
