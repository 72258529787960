import globalColors from '../theme/colors';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableData: {
        color: globalColors.textColor3,
        fontSize: "26px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    tableDataS: {
        color: globalColors.textColor1,
        fontSize: "26px",
        fontWeight: "700",
        letterSpacing: "0.15px"
    },
    tableDataR: {
        color: globalColors.semanticColor4,
        fontSize: "26px",
        fontWeight: "700",
        letterSpacing: "0.15px"
    },
    invalidTestText: {
        color: "#1C1C28",
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    mtbNotDetectedText: {
        color: globalColors.textColor1,
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    }
}));

export default function StripInterpretation ({ output, batchType }) {
    const classes = useStyles();

    return (
        <>
            {batchType === 'FL'
            ? <Grid item xs={12} md={3} lg={3} style={{ display: "flex", marginBottom: "435px" }}>
                <Table className={classes.table} size="small" style={{ width: "350px", border: "1px solid #555770", borderRadius: "0" }} padding="none" aria-label="simple table">
                    <TableBody style={{ scrollSnapAlign: 'start' }}>
                    {output.map((row, i) => {
                        return (
                            row.resultData[0] === 'MTB NOT DETECTED'
                            ? <TableRow key={i}>
                            <TableCell colSpan={11} align="center" style={{ width: "350px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.mtbNotDetectedText}>{row.resultData[0]}</Typography>
                            </TableCell>
                            </TableRow>
                            : row.resultData[0] === 'INVALID TEST' || row.resultData[0] === 'INDETERMINATE TEST'
                            ? <TableRow key={i}>
                            <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.invalidTestText}>{row.resultData[0]}</Typography>
                            </TableCell>
                            </TableRow>
                            : <TableRow key={i}>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[0]}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[1]}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[2]}</Typography>

                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[3]}</Typography>

                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[4]}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[5]}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableData}>{row.resultData[6]}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "54px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                                <TableRow>
                                    <TableCell align="center" style={{ width: "27px", maxWidth: "27px", height: "150px", borderRight: "1px solid #555770", color: "#555770" }}>
                            <Typography className={classes.tableDataS}>{row.resultData[7]}</Typography>

                                    </TableCell>
                                    <TableCell align="center" style={{ width: "27px", maxWidth: "27px", color: "#555770" }}>
                            <Typography className={classes.tableDataR}>{row.resultData[8]}</Typography>

                                    </TableCell>
                                </TableRow>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "54px", height: "150px", borderBottom: "1px solid #555770" }}>
                                <TableRow>
                                    <TableCell align="center" style={{ minWidth: "27px", maxWidth: "27px", height: "150px", borderRight: "1px solid #555770", color: "#555770" }}>
                                    <Typography className={classes.tableDataS}>{row.resultData[9]}</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "27px", maxWidth: "27px", color: "#555770" }}>
                            <Typography className={classes.tableDataR}>{row.resultData[10]}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableCell>
                            </TableRow>
                    )
                    })}
                    </TableBody>
                </Table>
            </Grid>
            : <Grid item xs={12} md={3} lg={4} style={{ display: "flex", marginBottom: "435px" }}>

                  <Table size="small" style={{ width: "350px",border: "1px solid #555770", borderRadius: "0" }} padding="none" aria-label="simple table">
                           <TableBody style={{ scrollSnapAlign: 'start' }}>
                           {output.map((row, i) => (
                               <TableRow key={i}>
                                   {row.resultData[0] === 'MTB NOT DETECTED'
                                    ? <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                    <Typography className={classes.mtbNotDetectedText}>{row.resultData[0]}</Typography>
                                    </TableCell>
                                    : row.resultData[0] === 'INVALID TEST' || row.resultData[0] === 'INDETERMINATE TEST'
                                    ? <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                    <Typography className={classes.invalidTestText}>{row.resultData[0]}</Typography>
                                    </TableCell>
                                    : <React.Fragment>
                                    <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[0]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[1]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[2]}</Typography>

                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[3]}</Typography>

                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[4]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[5]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[6]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[7]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770", color: "#555770" }}>
                                   <Typography className={classes.tableData}>{row.resultData[8]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "90px", height: "150px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                                        <TableRow>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #555770", color: "#555770" }}>
                                                <Typography className={row.resultData[9] === 'S' ? classes.tableDataS : classes.tableDataR}>{row.resultData[9]}</Typography>
                                            </TableCell>
                                            <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#555770", borderRight: "1px solid #555770" }}>
                                                <Typography className={row.resultData[10] === 'S' ? classes.tableDataS : classes.tableDataR}>{row.resultData[10]}</Typography>
                                            </TableCell>
                                            
                                            {/* <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#555770", borderRight: "1px solid #555770" }}>
                                            <Typography className={row.resultData[11] === 'S' ? classes.tableDataS : classes.tableDataR}>{row.resultData[11]}</Typography>

                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#555770", borderRight: "1px solid #555770" }}>
                                            <Typography className={row.resultData[12] === 'S' ? classes.tableDataS : classes.tableDataR}>{row.resultData[12]}</Typography>

                                            </TableCell> */}

                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#555770" }}>
                                                <Typography className={row.resultData[13] === 'S' ? classes.tableDataS : classes.tableDataR}>{row.resultData[13]}</Typography>
                                           </TableCell>
                                       </TableRow>
                                   </TableCell>

                                   </React.Fragment>}

                               </TableRow>
                           ))}
                           </TableBody>
                       </Table>

                </Grid>
            }
        </>
    )
}
