export const SortingOrder = Object.freeze({
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  STRIPS_TO_REVIEW_FIRST: "strips_to_review_first"
})

export const InterpretationViewType = Object.freeze({
  PRIORITISATION_VIEW: 'prioritisation_view',
  SHEET_VIEW: 'sheet_view'
})

export const ScreenNames = Object.freeze({
  UPLOAD: 'upload',
  MATCH_LAB_IDS: 'match_lab_ids',
  BATCH_VALIDITY: 'batch_validity',
  INTERPRETATION: 'interpretation',
})
