import { DialogContent, Typography } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import globalUseStyle from "../theme/customComponents";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";


export default function ErrorDialog ({ onClose, open, errorHeading, errorContent }) {
  const globalClasses = globalUseStyle();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{errorHeading}</DialogTitle>
      <DialogContent style={{ paddingBottom: "20px" }}>
        <Typography style={{ paddingBottom: "10px", color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
            {errorContent}
        </Typography>
        <DialogActions>
        <Button className={globalClasses.blueButton} variant="contained" onClick={onClose} style={{ width: "100px", marginLeft: "-2px" }}> Okay </Button>
        </DialogActions>
      </DialogContent>

    </Dialog>
  );
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorHeading: PropTypes.string.isRequired,
  errorContent: PropTypes.string.isRequired,
};
