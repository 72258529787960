import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  status: {
    danger: "orange",
  },
  typography: {
    h1: {
      fontSize: "32px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "24px",
      letterSpacing: "0.1px"
    },
    h2: {
      fontSize: "24px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "24px",
      letterSpacing: "0.1px"
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
        textTransform: "none",
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "5px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "0px",
      },
    }
  },
});

export default theme;
