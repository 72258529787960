import { DialogContent, Typography } from "@material-ui/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  enabledArrowIcon: {
    cursor: "pointer"
  },
  disabledArrowIcon: {
    color: "lightgrey",
    cursor: "default"
  }
}))

export default function ImageCarousel ({ onClose, open, images, index = 0 }) {
  const classes = useStyles()
  const [currentIndex, setCurrentIndex] = React.useState(index);
    return (
      <div>
      <Dialog
      onClose={onClose}
      open={open}
    >
        <DialogContent style={{ backgroundColor: "white", paddingTop: "10px", paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        <CloseIcon onClick={onClose} style={{ cursor: "pointer", marginLeft: "10px" }}/>
          <Grid container display="flex" alignItems="center" justifyContent="center">
            <ArrowBackIcon className={currentIndex === 0 ? classes.disabledArrowIcon : classes.enabledArrowIcon} onClick={() => { currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(0) }} style={{ marginRight: "10px" }}/>
            <div style={{ height: "700px", width: "450px", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img src={images[currentIndex]} style={{ width: "100%", height: "100%" }} alt="Tips"/>
              <div style={{ backgroundColor: "#1A73E8", width: "450px", height: "58px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography style={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "700", lineHeight: "32px", letterSpacing: "0.1px" }}> Sheet {currentIndex + 1}</Typography>
              </div>
            </div>
            <ArrowForwardIcon className={currentIndex === images.length - 1 ? classes.disabledArrowIcon : classes.enabledArrowIcon} onClick={() => { currentIndex === images.length - 1 ? setCurrentIndex(currentIndex) : setCurrentIndex(currentIndex + 1) }} style={{ marginLeft: "10px" }}/>
          </Grid>
        </DialogContent>

    </Dialog>
    </div>
  );
}

ImageCarousel.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};
