import { makeStyles } from "@material-ui/core/styles";
import globalColors from "./colors";


const globalUseStyle = makeStyles((theme) => ({
    blueButton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#1967D2"),
        fontSize: "14px",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundColor: globalColors.brandColor1,
        "&:hover": {
          backgroundColor: globalColors.brandColor1,
          "@media (hover: none)": {
            backgroundColor: globalColors.brandColor1,
          },
        },
        // "&:Mui-disabled": {
        //   backgroundColor: globalColors.brandColor1,
        //   disabledBackground: globalColors.brandColor1,
        //   opacity: 0.3,
        //   color: theme.palette.getContrastText(globalColors.brandColor1)
        // },
        "&:disabled": {
          backgroundColor: globalColors.brandColor1,
          disabledBackground: globalColors.brandColor1,
          opacity: 0.3,
          color: theme.palette.getContrastText(globalColors.brandColor1)
        },
        // ":disabled": {
        //   backgroundColor: globalColors.brandColor1,
        //   disabledBackground: globalColors.brandColor1,
        //   opacity: 0.3,
        //   color: theme.palette.getContrastText(globalColors.brandColor1)
        // }

      },
    dialogButton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText(globalColors.brandColor1),
        backgroundColor: globalColors.brandColor1,
        height: "36px",
        "&:hover": {
            backgroundColor: globalColors.brandColor1,
            "@media (hover: none)": {
                backgroundColor: globalColors.brandColor1
            }
            },
        "&:disabled": {
          backgroundColor: globalColors.brandColor1,
          opacity: 0.3,
          color: theme.palette.getContrastText(globalColors.brandColor1)
        }
    },
    cancelButton: {
        width: "94px",
        height: "36px",
        borderColor: globalColors.brandColor1,
        float: "right",
        color: globalColors.brandColor1
    },
    outlinedButton: {
      borderColor: globalColors.brandColor1,
      float: "right",
      color: globalColors.brandColor1
    }
}));

export default globalUseStyle;
