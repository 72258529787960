import React from "react";
import "./IndicatorComponent.css"

export const IndicatorComponent = ({ currentSlideNumber = 0, totalSlideNumber = 3 }) => {
  const items = [...Array(totalSlideNumber).keys()]
  return (
    <div className="background">
      {items.map((i) => {
        return (
          <div className="circle" style={{ marginLeft: "12px" }} key={i}>
            <div
              className="mover"
              style={{
                transform: `translateX(${(currentSlideNumber - i) * 40}px)`,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
