import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import DraggableStrip from '../components/draggableStrip';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useBreakpoint } from '../utils';

export default function SampleStrips ({ input, batchType, handleBandChange }) {
    const breakpoint = useBreakpoint()
    const checkboxSide = breakpoint === "lg" ? "16px": "12px"
    const checkboxDistanceWideFL = breakpoint === "lg" ? "20px": "11px"
    const checkboxDistanceNarrowFL = breakpoint === "lg" ? "-2px": "-5px"
    const checkboxDistanceNarrowSL = breakpoint === "lg" ? "-0.2px": "-3.7px"
    const useStyles = makeStyles((theme) => ({
        root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        icon: {
            borderRadius: 0,
            border: '1px solid #5F6368',
            width: checkboxSide,
            height: checkboxSide,
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
              },
        },
        checkedIcon: {
            borderRadius: 0,
            border: '1px solid #48D292',
            width: checkboxSide,
            height: checkboxSide,
            backgroundColor: '#48D292',
            'input:hover ~ &': {
                backgroundColor: '#48D292',
                },
        },
        iconOrangeBorder: {
            borderRadius: 0,
            border: '2px solid #F57C00',
            width: checkboxSide,
            height: checkboxSide,
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
                },
        },
        checkedIconOrangeBorder: {
            borderRadius: 0,
            border: '2px solid #F57C00',
            width: 16,
            height: 16,
            backgroundColor: '#48D292',
            'input:hover ~ &': {
                backgroundColor: '#48D292',
                },
        }
    }));
    const [pos, setPos] = React.useState(0)
    const classes = useStyles()

    function checkboxes (row) {
        return (
        <>
        {
            batchType === 'FL'
            ? <div style={{ display: "flex", zIndex: "2", paddingLeft:  "40px" }}>
                {row?.bandData?.map((j, index) => {
                    if (index <= 26) {
                    return (
                    <Grid container alignItems="center" display="flex" direction="column" style={{ width: "23px", marginRight: index === 2 || index === 15 || index === 19 ? checkboxDistanceWideFL : checkboxDistanceNarrowFL }}>
                        <Grid item>
                        { j.confidence === false
                        ? <Divider orientation="vertical" style={{ marginTop: "-20px", marginBottom: "-10px", height: "30px", background: "#F57C00", width: "2px" }}/>
                        : ''}
                        </Grid>
                        <Grid item>
                            <Checkbox
                                className={classes.root}
                                disableRipple
                                checked = {j.bandPresent}
                                icon={<span className={j.confidence === true ? classes.icon : classes.iconOrangeBorder} />}
                                checkedIcon={<span className={j.confidence === true ? classes.checkedIcon : classes.checkedIconOrangeBorder} />}
                                onChange={handleBandChange(row.sheetId, row.rowId, index)}
                            />
                        </Grid>
                    </Grid>)
                    }
                return true
                })
                }
            </div>
            : <div style={{ display: "flex", zIndex: "2", paddingLeft: "48px" }} >
                {row.bandData.map((j, index) => {
                    if (index <= 26) {
                    return (
                        // , paddingRight: index === 2 || index === 15 || index === 19 ? "30px" : "0px"
                    <Grid container alignItems="center" display="flex" direction="column" style={{ width: "22.25px", marginRight: checkboxDistanceNarrowSL }}>
                        <Grid item>
                        { j.confidence === false
                        ? <Divider orientation="vertical" style={{ marginTop: "-30px", marginBottom: "-4px", height: "34px", background: "#F57C00", width: "2px" }}/>
                        : ''}
                        </Grid>
                        <Grid item>
                            <Checkbox
                                style={{
                                    transform: "scale(1)",
                                    paddingRight: "0px",
                                    paddingLeft: "0px"
                                }}
                                className={classes.root}
                                disableRipple
                                checked = {j.bandPresent}
                                icon={<span className={j.confidence === true ? classes.icon : classes.iconOrangeBorder} />}
                                checkedIcon={<span className={j.confidence === true ? classes.checkedIcon : classes.checkedIconOrangeBorder} />}
                                onChange={handleBandChange(row.sheetId, row.rowId, index)}
                            />
                        </Grid>
                    </Grid>
                    )
}
                    return true;
                    })}
            </div>
        }
        </>
        )
    }

    return (
        <>
            <Grid item xs={12} md={6} lg={7} style={{ display: "flex", flexDirection: "column" }}>
                {input.map((row) => (
                <div style={{ scrollSnapAlign: 'start', height: '150px' }}>
                    <Grid container style={{ paddingTop: "20px", paddingBottom: "20px", height: '145px' }}>
                        {/* Strip with checkboxes */}
                        <Grid item md={12} lg={12} style={{ display: "flex", flexDirection: "column" }}>
                            <DraggableStrip pos={pos} setPos={setPos} image={row.stripImagePath}></DraggableStrip>
                            {checkboxes(row)}
                        </Grid>
                    </Grid>
                    <Divider />
                </div>
                ))}
            </Grid>
        </>
    )
}
