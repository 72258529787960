import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import globalColors from '../theme/colors';
import globalUseStyle from '../theme/customComponents';
import Grid from '@material-ui/core//Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  instructionContent: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0.1px",
    alignItems: "center",
    color: globalColors.textColor2
  },
  numberPoint: {
    marginRight: "14px",
    fontSize: "13px",
    fontWeight: "700",
    lineHeight: "21px",
    alignItems: "center",
    letterSpacing: "0.1px",
    backgroundColor: "#48D292",
    width: "18px",
    height: "18px",
    borderRadius: "9px",
    align: "center",
    color: "white" 
  },
  bulletPoint: {
    marginRight: "14px", 
    alignItems: "flex-start", 
    letterSpacing: "0.1px", 
    backgroundColor: globalColors.textColor2, 
    width: "4px", 
    height: "4px", 
    borderRadius: "2px", 
    align: "flex-start", 
    color: "white"
  }
}));



const ScannerInstructionsDialog = ({ showDialog, setShowDialog }) => {
    // const dispatch = useDispatch();
    const classes = useStyles();
    const globalClasses = globalUseStyle();
    // const [showDialog, setShowDialog] = React.useState(true)

  return (
    <React.Fragment>
        <Dialog open={showDialog} maxWidth={false} PaperProps={{style: {backgroundColor: globalColors.backgroundColor2, boxShadow: 'none'}}}>
            <Grid style={{ width: "743px", height: "720px", display: "flex", marginTop: "28px", marginLeft: "24px", flexDirection: "column"}}>
            <Typography style={{ fontSize: "28px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", alignItems: "center", paddingBottom: "10px" }} gutterBottom>
                Connect your scanner
            </Typography>
            <Grid container alignItems="center">
              <Grid item lg={2}>
              <img src="/scanner_icon.png" alt="Scanner" />
              </Grid>
              <Grid item lg={1}>
              <img src={require("../constants/link.png")} alt="Link" />
              </Grid>
              <Grid item lg={2}>
              <img src="/monitor_screen_icon.png" alt="Monitor" />
              </Grid>
            </Grid>
            <Typography className={classes.instructionContent} style={{paddingTop: "25px"}} gutterBottom>
              Follow these steps to connect your scanner
            </Typography>
            <List>
              <ListItem >
              <ListItemIcon >
              <Typography align="center" className={classes.numberPoint}>
                   1
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
                Ensure your scanner is on
              </Typography>
              </ListItem>
              <ListItem>
              <ListItemIcon>
              <Typography align="center" className={classes.numberPoint}>
                   2
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
              Take a self-test: Read through the supplied documentation for details of a self-test you can perform on the scanner without a computer to diagnose any problems.
              </Typography>
              </ListItem>
              <ListItem>
              <ListItemIcon>
              <Typography align="center" className={classes.numberPoint}>
                   3
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
              Check for a connection issue:
              </Typography>
              </ListItem>
              <div style={{ paddingLeft: "40px" }}>
              <ListItem>
              <ListItemIcon>
              <Typography align="center" className={classes.bulletPoint}>
                   .
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
                USB scanner: Check the USB ports and USB cables to ensure they are connected properly.
              </Typography>
              </ListItem>
              <ListItem>
              <ListItemIcon>
              <Typography className={classes.bulletPoint}>
                   .
                </Typography>
              </ListItemIcon>
                <Typography className={classes.instructionContent}>
                Network scanner: Ensure your computer has a good Internet connection, and your scanner connects to your computer internet. In addition, if you’re using a VPN on your computer, try disconnecting the VPN from your computer and scanning again.
                </Typography>
              </ListItem>
              </div>
              <ListItem>
              <ListItemIcon>
              <Typography align="center" className={classes.numberPoint}>
                   4
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
              Outdated scanner drive: An outdated scanner driver can cause your scanner not to work, so you should update your scanner driver and try again.
              </Typography>
              </ListItem>
              <ListItem>
              <ListItemIcon>
              <Typography align="center" className={classes.numberPoint}>
                   5
                </Typography>
              </ListItemIcon>
              <Typography className={classes.instructionContent}>
              Enable Windows Image Acquisition (WIA): Enable WIA service in your computer
              </Typography>
              </ListItem>
            </List>
            <Divider variant="middle" style={{ marginTop: "28px", marginBottom: "28px" }}/>
            <Grid container>
            <Grid item xs={6} md={6} lg={6} style={{ display: "flex", alignItems: "center"}}>
            <Typography align="left" style={{ fontSize: "14px", fontWeight: "500", lineHeight: "18px", alignItems: "center", letterSpacing: "0.1px" }}>
            CALL 18001166666 FOR HELP
            </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
            <DialogActions>
                <Button className={globalClasses.blueButton} style={{ width: "125px" }} onClick={() => { setShowDialog(false) }}>Done</Button>
            </DialogActions>
            </Grid>
            </Grid>
            </Grid>
        </Dialog>
    </React.Fragment>
  );
}

export default ScannerInstructionsDialog;
