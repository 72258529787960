import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function ReferenceBand ({ batchType }) {
    return (
        <>
            <Grid item xs={12} md={6} lg={7} style={{ display: "flex", flexDirection: "column", paddingTop: "40px" }}>
                <div style={{ marginLeft: "30px", marginRight: "30px" }}>
                {
                    batchType === 'FL'
                    ?
                    // Reference band for FL
                    <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_FL.png')}></img>
                    :
                    // Reference band for SL
                    <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_SL.png')}></img>
                }
                </div>
            </Grid>
        </>
        )
}
