import React, { useState, useEffect } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import globalColors from '../theme/colors';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import SaveWarning from './saveWarning';

export default function BreadCrumbList ({ pageList, setState, warningMessage }) {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
        <Grid container style={{ background: "white", padding: "16px" }} >
            <Grid item xs={12} md={12} lg={12}>
                <div style={{ backgroundColor: globalColors.borderColor, padding: "6px 8px 6px 8px" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link style={{ color: globalColors.brandColor1, fontSize: "14px", fontWeight: "400", lineHeight: "21px", letterSpacing: "0.1px" }} onClick={() => setShowDialog(true)}>
                        Dashboard
                        </Link>
                        {
                            pageList.map((row) => {
                                return (
                                    <Link style={{ color: globalColors.textColor1, fontSize: "14px", fontWeight: "400", lineHeight: "21px", letterSpacing: "0.1px"  }}>
                                        {row}
                                    </Link>
                                )
                            })

                        }
                    </Breadcrumbs>
                </div>
            </Grid>
        </Grid>
        <SaveWarning changePage={() => setState('d')} warningMessage={warningMessage} showDialog={showDialog} setShowDialog={setShowDialog}/>
        </>
    )
}
