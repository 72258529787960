import { firebaseAnalytics } from "./firebaseConfig";

const firebaseEvents = {
    SHEET_UPLOADED: 'sheet_uploaded',
    BAD_IMAGE_QUALITY: 'bad_image_quality',
    PREVIEW_SHEET: 'preview_sheet',
    INTERPRETATION_VIEW_TYPE: 'interpretation_view_type',
    SORT_CLICK: 'sort_click',
    CONTROL_STRIPS_ABSENT: 'control_strips_absent'
}

const getDefaultParams = () => ({ event_time: new Date().toLocaleString() })

export const logSheetUploadedEvent = (duplicateSheet) => {
    firebaseAnalytics.logEvent(firebaseEvents.SHEET_UPLOADED, {
        ...getDefaultParams(),
        duplicate_sheet: duplicateSheet
    })
}

export const logBadImageQualityEvent = () => {
    firebaseAnalytics.logEvent(firebaseEvents.SHEET_UPLOADED, {
        ...getDefaultParams(),
        bad_image_quality: true
    })
}

export const logPreviewSheetEvent = (screenName) => {
    firebaseAnalytics.logEvent(firebaseEvents.PREVIEW_SHEET, {
        ...getDefaultParams(),
        page_name: screenName
    })
}

export const logInterpretationViewTypeClickEvent = (interpretationViewType) => {
    firebaseAnalytics.logEvent(firebaseEvents.INTERPRETATION_VIEW_TYPE, {
        ...getDefaultParams(),
        view_type: interpretationViewType
    })
}

export const logClickOnSortOptionEvent = (sortingOrder) => {
    firebaseAnalytics.logEvent(firebaseEvents.SORT_CLICK, {
        ...getDefaultParams(),
        sorting_order: sortingOrder
    })
}

export const logControlStripsAbsent = (masterMixControlStripCount, positiveControlStripCount, extractionControlStripCount) => {
    firebaseAnalytics.logEvent(firebaseEvents.CONTROL_STRIPS_ABSENT, {
        ...getDefaultParams(),
        mastermix_control: masterMixControlStripCount,
        positive_control: positiveControlStripCount,
        extraction_control: extractionControlStripCount
    })
}

export const logUserDetails = (userId) => {
    firebaseAnalytics.setUserId(userId);
    firebaseAnalytics.setUserProperties({ user_identifier: userId });
}
