import Grid from '@material-ui/core/Grid';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
      tableData: {
        color: "#555770",
        fontSize: "26px",
        fontWeight: "400",
        letterSpacing: "0.15px"
      }
}));

export default function InterpretationHeader ({ batchType }) {
    const classes = useStyles()
    return (
        <>
        {
            batchType === 'FL'
            // Phenotypic and Genotypic interpretation table header for FL
            ? <Grid item xs={12} md={3} lg={3} style={{ display: "flex", paddingTop: "40px" }}>
                <Table className={classes.table} size="small" style={{ width: "350px", border: "1px solid #555770", borderRadius: "0" }} padding="none" aria-label="simple table">
                    <TableRow>
                        <TableCell align="center" style={{ width: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "40px" }}>
                                TUB
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                            rpoB WT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                            rpoB MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                            katG WT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                            katG MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                                inhA WT
                            </Typography>
                        </TableCell>

                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "40px" }}>
                                inhA MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: "56px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <TableRow>
                                <TableCell colSpan={2} align="center" style={{ height: "35px", borderBottom: "1px solid #555770" }}>
                                    <Typography style={{ color: "#555770" }}>
                                    RMP
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{ minWidth: "28px", maxWidth: "28px", borderRight: "1px solid #555770", borderBottom: "0px" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "40px", fontSize: "15px" }}>
                                        Sensitive
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "28px", maxWidth: "28px", height: "75px", borderBottom: "0px" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "40px", fontSize: "15px" }}>
                                        Resistant
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: "56px", borderBottom: "1px solid #555770" }}>
                            <TableRow>
                                <TableCell colSpan={2} align="center" style={{ height: "35px", borderBottom: "1px solid #555770" }}>
                                    <Typography style={{ color: "#555770" }}>
                                    INH
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableCell align="center" style={{ minWidth: "28px", maxWidth: "28px", height: "75px", borderRight: "1px solid #555770", borderBottom: "0px" }}>
                                <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "40px", fontSize: "15px" }}>
                                Sensitive
                                </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "28px", maxWidth: "28px", borderBottom: "0px" }}>
                                <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "40px", fontSize: "15px" }}>
                                Resistant
                                </Typography>
                            </TableCell>
                        </TableCell>

                    </TableRow>
                </Table>
            </Grid>
            :
            // Phenotypic and Genotypic interpretation table header for SL
            <Grid item xs={12} md={3} lg={3} style={{ display: "flex", paddingTop: "40px" }}>
                <Table className={classes.table} size="small" style={{ width:"360px", border: "1px solid #555770", borderRadius: "0" }} padding="none" aria-label="simple table">
                    <TableRow>
                        <TableCell align="center" style={{ width: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "130px" }}>
                                TUB
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                        <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                            gyrA WT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                            gyrA MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                            gyrB WT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                            gyrB MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                                rrs WT
                            </Typography>
                        </TableCell>

                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                                rrs MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                                eis WT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", whiteSpace: "nowrap", marginTop: "130px" }}>
                                eis MUT
                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{minWidth: "90px", borderRight: "1px solid #555770", borderBottom: "1px solid #555770" }}>
                            <TableRow>
                                <TableCell colSpan={3} align="center" style={{ height: "35px", borderBottom: "1px solid #555770" }}>
                                    <Typography style={{ color: "#555770", fontSize: '15px' }}>
                                    Phenotypic resistance
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{ width: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "0px" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "90px", fontSize: "13px" }}>
                                    FLQ
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "130px", borderBottom: "0px", borderRight: "1px solid #555770" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "90px", fontSize: "13px" }}>
                                    KAN/AMK/CAP
                                    </Typography>
                                </TableCell>
                                {/* <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #555770", borderBottom: "0px" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "90px", fontSize: "13px" }}>
                                    KAN/CAP/VIO
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "75px", borderBottom: "0px", borderRight: "1px solid #555770" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#555770", marginTop: "90px", fontSize: "13px" }}>
                                    KAN/AMK/CAP/VIO
                                    </Typography>
                                </TableCell> */}
                                <TableCell align="center" style={{ width: "30px", maxWidth: "30px", height: "75px", borderBottom: "0px" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, whiteSpace: "nowrap", color: "#555770", marginTop: "90px", fontSize: "13px" }}>
                                    low-level KAN
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableCell>
                    </TableRow>
                </Table>
        </Grid>
        }
        </>
    )
}
