/* eslint-disable react-hooks/exhaustive-deps */
// import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import Grid from '@material-ui/core/Grid';
// import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import InterpretationHeader from './interpretationHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useEffect } from 'react';
import ReferenceBand from './referenceBand';
import SampleStrips from './sampleStrip';
import Snackbar from '@material-ui/core/Snackbar';
import StripInterpretation from './stripInterpretation';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { toggleFlag } from '../actions/global';
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    icon: {
        borderRadius: 0,
        border: '1px solid #5F6368',
        width: 16,
        height: 16,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
          },
    },
    checkedIcon: {
        borderRadius: 0,
        border: '1px solid #429637',
        width: 16,
        height: 16,
        backgroundColor: '#429637',
        'input:hover ~ &': {
            backgroundColor: '#429637',
            },
    },
    iconOrangeBorder: {
        borderRadius: 0,
        border: '2px solid #F57C00',
        width: 16,
        height: 16,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            },
    },
    checkedIconOrangeBorder: {
        borderRadius: 0,
        border: '2px solid #F57C00',
        width: 16,
        height: 16,
        backgroundColor: '#429637',
        'input:hover ~ &': {
            backgroundColor: '#429637',
            },
    },
    tableData: {
        color: "#1A73E8",
        fontSize: "26px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    invalidTestText: {
        color: "#C5221F",
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    mtbNotDetectedText: {
        color: "#80868B",
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    flHeader: {
        width: "1360px",
        display: "flex",
        height: "252px",
        paddingTop: "10px"
    },
    slHeader: {
        width: "1360px",
        display: "flex",
        height: "241px",
        paddingTop: "10px"
    },
    flStrips: {
        width: "1360px",
        display: "flex",
        height: "600px",
        overflowY: 'overlay',
        paddingBottom: "20px",
        paddingTop: "12px",
        gridAutoRows: '1fr',
        scrollSnapType: 'y mandatory'
    },
    slStrips: {
        width: "1360px",
        display: "flex",
        height: "600px",
        overflowY: 'overlay',
        paddingBottom: "20px",
        paddingTop: "12px",
        gridAutoRows: '1fr',
        scrollSnapType: 'y mandatory'
    }
}));

const InterpretationTable = ({ batchType, input, output, setInput, setOutput, inputToDisplay, outputToDisplay }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openPopup, setOpenPopup] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState(undefined);
    const [popupMessageList, setPopupMessageList] = React.useState([]);

    function getRowIndex (sheetId, rowId) {
        return input.findIndex(row => row.sheetId === sheetId && row.rowId === rowId);
     }

    const handleBandChange = (sheetId, rowId, j) => (event) => {
        const i = getRowIndex(sheetId, rowId)
        input[i].bandData[j].bandPresent = !input[i]?.bandData[j]?.bandPresent
        if (batchType === 'FL') {
            output[i].resultData[0] = '';
            if (!input[i].bandData[0].bandPresent || !input[i].bandData[1].bandPresent) {
                output[i].resultData[0] = 'INVALID TEST';
            } else if (!input[i].bandData[2].bandPresent && (input[i].bandData[0].bandPresent && input[i].bandData[1].bandPresent)) {
                output[i].resultData[0] = 'MTB NOT DETECTED';
            } else if (input[i].bandData[2].bandPresent && (!input[i].bandData[3].bandPresent || !input[i].bandData[16].bandPresent || !input[i].bandData[20].bandPresent)) {
                output[i].resultData[0] = 'INDETERMINATE TEST';
            }
            if (output[i].resultData[0] === '') {
                output[i].resultData[0] = input[i].bandData[2].bandPresent ? '+' : '-'; // TUB
                output[i].resultData[1] = input[i].bandData.slice(4, 12).reduce(function (rpobWT, val) { return rpobWT && val.bandPresent; }, true) ? '+' : '-'; // rpobWT
                output[i].resultData[2] = input[i].bandData.slice(12, 16).reduce(function (rpobMUT, val) { return rpobMUT || val.bandPresent; }, false) ? '+' : '-'; // rpobMUT
                output[i].resultData[3] = input[i].bandData.slice(17, 18).reduce(function (katGWT, val) { return katGWT && val.bandPresent; }, true) ? '+' : '-'; // katGWT
                output[i].resultData[4] = input[i].bandData.slice(18, 20).reduce(function (katGMUT, val) { return katGMUT || val.bandPresent; }, false) ? '+' : '-'; // katGMUT
                output[i].resultData[5] = input[i].bandData.slice(21, 23).reduce(function (inhAWT, val) { return inhAWT && val.bandPresent; }, true) ? '+' : '-'; // inhAWT
                output[i].resultData[6] = input[i].bandData.slice(23, 27).reduce(function (inhAMUT, val) { return inhAMUT || val.bandPresent; }, false) ? '+' : '-'; // inhAMUT
                // RMP
                if (output[i].resultData[1] === '+' && output[i].resultData[2] === '-') {
                    output[i].resultData[7] = 'S'
                    output[i].resultData[8] = ''
                } else {
                    output[i].resultData[7] = ''
                    output[i].resultData[8] = 'R'
                }
                // INH
                if (output[i].resultData[3] === '+' && output[i].resultData[4] === '-' && output[i].resultData[5] === '+' && output[i].resultData[6] === '-') {
                    output[i].resultData[9] = 'S'
                    output[i].resultData[10] = ''
                } else {
                    output[i].resultData[9] = ''
                    output[i].resultData[10] = 'R'
                }
            }
        } else { // SL
            output[i].resultData[0] = '';
            if (!input[i].bandData[0].bandPresent || !input[i].bandData[1].bandPresent) {
                output[i].resultData[0] = 'INVALID TEST';
            } else if (!input[i].bandData[2].bandPresent && (input[i].bandData[0].bandPresent && input[i].bandData[1].bandPresent)) {
                output[i].resultData[0] = 'MTB NOT DETECTED';
            } else if (input[i].bandData[2].bandPresent && (!input[i].bandData[3].bandPresent || !input[i].bandData[13].bandPresent || !input[i].bandData[17].bandPresent || !input[i].bandData[22].bandPresent)) {
                output[i].resultData[0] = 'INDETERMINATE TEST';
            }

            if (output[i].resultData[0] === '') {
                output[i].resultData[0] = input[i].bandData[2].bandPresent ? '+' : '-'; // TUB
                // gyrAWT
                output[i].resultData[1] = input[i].bandData.slice(4, 7).reduce(function (gyrAWT, val) { return gyrAWT && val.bandPresent; }, true) ? '+' : '-';
                // gyrAMUT
                output[i].resultData[2] = input[i].bandData.slice(7, 13).reduce(function (gyrAMUT, val) { return gyrAMUT || val.bandPresent; }, false) ? '+' : '-';
                // gyrBWT
                output[i].resultData[3] = input[i].bandData.slice(14, 15).reduce(function (gyrBWT, val) { return gyrBWT && val.bandPresent; }, true) ? '+' : '-';
                // gyrBMUT
                output[i].resultData[4] = input[i].bandData.slice(15, 17).reduce(function (gyrBMUT, val) { return gyrBMUT || val.bandPresent; }, false) ? '+' : '-';
                // rrsWT
                output[i].resultData[5] = input[i].bandData.slice(18, 20).reduce(function (rrsWT, val) { return rrsWT && val.bandPresent; }, true) ? '+' : '-';
                // rrsMUT
                output[i].resultData[6] = input[i].bandData.slice(20, 22).reduce(function (rrsMUT, val) { return rrsMUT || val.bandPresent; }, false) ? '+' : '-';
                // eisWT
                output[i].resultData[7] = input[i].bandData.slice(23, 26).reduce(function (eisWT, val) { return eisWT && val.bandPresent; }, true) ? '+' : '-';
                // eisMUT
                output[i].resultData[8] = input[i].bandData.slice(26, 27).reduce(function (eisMUT, val) { return eisMUT || val.bandPresent; }, false) ? '+' : '-';

                // FLQ
                if (output[i].resultData[1] === '+' && output[i].resultData[2] === '-' && output[i].resultData[3] === '+' && output[i].resultData[4] === '-') {
                    output[i].resultData[9] = 'S'
                } else {
                    output[i].resultData[9] = 'R'
                }

                // KAN/AMK/CAP
                if (output[i].resultData[5] === '+' && output[i].resultData[6] === '-') {
                    output[i].resultData[10] = 'S'
                } else {
                    output[i].resultData[10] = 'R'
                }

                // low-level KAN
                if (output[i].resultData[7] === '+' && output[i].resultData[8] === '-') {
                    output[i].resultData[13] = 'S'
                } else {
                    output[i].resultData[13] = 'R'
                }
            }
        }
        var isResistant = output[i].resultData.reduce((prev, curr) => prev || curr === 'R', false )
        input[i].isResistant = isResistant
        output[i].isResistant = isResistant
        setInput([...input])
        setOutput([...output])
    };

    useEffect(() => {
        if (popupMessageList.length && !popupMessage)
        {
            setPopupMessage(popupMessageList[0]);
            setPopupMessageList(prev => prev.slice(1));
            setOpenPopup(true);
        }
        else if (popupMessageList.length && popupMessage && openPopup)
            setOpenPopup(false);

    }, [openPopup ,popupMessage ,popupMessageList]);

    function handleFlagClick (sheetId, rowId) {
        const i = getRowIndex(sheetId, rowId);
        
        dispatch(toggleFlag(rowId)).then(() => {
            input[i].isFlagged = !input[i].isFlagged
            output[i].isFlagged = !output[i].isFlagged
            setInput([...input]);
            setOutput([...output]);
        }).then(() => setPopupMessageList(prev => [...prev, input[i].isFlagged ? "Sample has been flagged": "Sample has been unflagged"]))
    }
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenPopup(false);
      };
    function showPopups(){
        const action = (
            <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            </>
        )
        var vertical = 'bottom'
        var horizontal = 'left'
        var popup = <Snackbar anchorOrigin={{ vertical, horizontal }} open={openPopup} TransitionProps={{ onExited: () => {setPopupMessage(undefined)} }} autoHideDuration={6000} onClose={handleClose} message={popupMessage} action={action}/>
        return (popup);
    }

    return (
        <React.Fragment>
            {showPopups()}
            {/* Interpretation Header */}
            <Grid container spacing={2} className={batchType === 'FL' ? classes.flHeader : classes.slHeader}>
                <Grid item md={1} lg={batchType === 'FL' ? 2 : 1}>
                </Grid>
                <ReferenceBand batchType={batchType} />
                <InterpretationHeader batchType={batchType} />
            </Grid>
            {/* Interpretation Rows */}
            <Grid container spacing={2} className={batchType === 'FL' ? classes.flStrips : classes.slStrips}>
                <Grid item xs={12} md={1} lg={batchType === 'FL' ? 2 : 1} style={{ display: "flex", flexDirection: "column" }}>
                  {/* Sheet and serial number */}
                  {inputToDisplay.map((row) => (
                    <div style={{ scrollSnapAlign: 'start', height: '150px', justifyContent: 'center' }}>
                        <Grid container justifyContent="center" alignItems="center" style={{ paddingTop: "20px", paddingBottom: "20px", height: '145px' }}>
                            <Grid item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ alignSelf: "center" }}>
                                    {/* <InfoIcon style={{ color: "#FB8C00" }} fontSize="small"/> */}
                                    <IconButton onClick={() => {}}>
                                    <MoreVertIcon style={{ color: '#2B78C2' }}/>
                                    </IconButton>
                                    {/* <Divider orientation="vertical" style={{width: '100%'}} flexItem />  */}
                                    <IconButton title="Flag for Microbiologist to check" onClick={() => handleFlagClick(row.sheetId, row.rowId)}>
                                    {
                                        row.isFlagged
                                        ? <FlagIcon style={{ color: "#FF5757" }} fontSize="medium" />
                                        : <FlagOutlinedIcon style={{ color: "#9AA0A6" }} fontSize="medium"/>
                                    }
                                    </IconButton>
                                    <Typography align='center'>
                                        Sheet {row.sheetNo}
                                    </Typography>
                                    <Typography align='center'>
                                        {row.labSampleId}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                  ))}
                </Grid>
                <SampleStrips input={inputToDisplay} batchType={batchType} handleBandChange={handleBandChange}/>
                <StripInterpretation output={outputToDisplay} batchType={batchType}/>
        </Grid>
        {showPopups()}
        </React.Fragment>
    );
}

export default InterpretationTable;
